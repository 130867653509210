import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams for getting ID from URL
import axios from 'axios';

const OrderDetail = () => {
    const { orderId } = useParams(); // Get order ID from URL
    const navigate = useNavigate(); // Create navigate function
    const [order, setOrder] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [locationId, setLocationId] = useState(null); // State to store location ID
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted
        const fetchOrderDetail = async () => {
            try {
              console.log('Order ID:', orderId);
                const response = await fetch(`https://89.116.187.72:3001/orderDetails/${orderId}`, {
                    method: 'GET'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('API Response:', data); // Log the response
                if (isMounted) {
                    setOrder(data.order);
                    setLocationId(data.order.location_id);
                    setLoading(false);
                }
            } catch (err) {
                console.error('Error fetching order details:', err);
                if (isMounted) {
                    setError(`Failed to fetch order details: ${err.message}`);
                    setLoading(false);
                }
            }
        };

        fetchOrderDetail();
        return () => {
            isMounted = false; // Cleanup function to avoid setting state on an unmounted component
        };
    }, [orderId]);

    useEffect(() => {
      if (order && order.customer_id) {
        fetchCustomerDetails(order.customer_id);
      }
    }, [order]);

  const fetchCustomerDetails = async (customerId) => {
    let isMounted = true; // Track if the component is mounted
        try {
            const response = await fetch(`https://89.116.187.72:3001/customer/${customerId}`, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Customer Response:', data); // Log the response
            if (isMounted) {
                setCustomer(data.customer);
                setLoading(false);
            }
        } catch (err) {
            console.error('Error fetching order details:', err);
            if (isMounted) {
                setLoading(false);
            }
        }
    }

  const handleCompleteOrder = async () => {
    if (window.confirm('Are you sure you want to mark this order as completed?')) {
      try {
        const response = await fetch(`https://89.116.187.72:3001/orders/${orderId}`,
            {
              method: 'PUT',
              "order": {
                "location_id": locationId,
                "version": order.version,
                "metadata": {
                  "OrderStatus": "Closed"
                }
              }
            }
          );
        alert('Order updated successfully!');
        setTimeout(() => {
        navigate('/orders'); 
      }, 1000); 
      } catch (err) {
        setLoading(false);
        setError(`Failed to mark order as completed: ${err.message}`);
      }
    }
  };

  if (error) return <div>Error fetching order details.</div>;
  if (loading) return <div>Loading...</div>;

  return (
    <div className="container order-detail">
      <h2>Order Details</h2>
      {order && (
        <>
          <div className="order-info">
            <p><strong>Order ID:</strong> {order.id}</p>
            <p><strong>Status:</strong> {(order.closed_at != null || order.metadata?.OrderStatus === 'Closed') ? <span className="completed">Completed</span> : <span className="pending">Pending</span>}</p>
            <p><strong>Ordered At:</strong> {new Date(order.created_at).toLocaleString()}</p>
            {/* <p><strong>Closed At:</strong> {order.closed_at ? new Date(order.closed_at).toLocaleString() : 'Not Closed Yet'}</p> */}
            <p><strong>Customer Name:</strong> {order.metadata?.customerName ?? (customer?.given_name != null ? `${customer.given_name}` + ' ' + `${customer?.family_name != null ? customer.family_name : ''}` : 'N/A')}</p>
          </div>

          {order.modifiers && Array.isArray(order.modifiers) && order.modifiers.length > 0 && (
            <div className="modifiers">
              <h4>Modifiers</h4>
              <ul>
                {order.modifiers.map((modifier, index) => (
                  <li key={index}>
                    <strong>{modifier.modifier_list_name || 'Unknown List'}:</strong> {modifier.modifier_name || 'Unknown Modifier'}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {order.line_items && Array.isArray(order.line_items) && order.line_items.length > 0 ? (
            <table className="order-detail-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Category</th>
                  <th>Variation</th>
                  <th>Modifiers</th>
                </tr>
              </thead>
              <tbody>
                {order.line_items.map((item, index) => (
                  <tr key={index}>
                    {(item.metadata?.type != null) ? <td>{item.name}</td> : <td className= 'left-margin'>{item.name}</td>}
                    <td>{item.quantity || '0'}</td>
                    <td>{item.metadata?.type ? (item.metadata.type.charAt(0).toUpperCase() + item.metadata.type.slice(1)) : ''}</td>
                    <td>{item.metadata?.type ? item.variation_name : 'cookie'}</td>
                    <td>
                        {item.modifiers && item.modifiers.length > 0 ? (
                            item.modifiers.map((modifier, row) => (
                            <div key={modifier.uid}>
                                {row + 1}. {modifier.name}
                            </div>
                            ))
                        ) : (
                            item.metadata?.type ? '' : 'No modifiers'
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No items found.</p>
          )}

          {order.metadata?.OrderStatus !== 'Closed' && (
            <button onClick={handleCompleteOrder}>
              Mark as Completed
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default OrderDetail;