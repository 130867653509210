import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
const api = axios.create({
  baseURL: 'https://connect.squareup.com',  // Set the base URL
});
const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [i, setI] = useState(1);
   // Handle row click
   const navigate = useNavigate();
   const handleRowClick = (orderId) => {
    navigate(`/order/${orderId}`); // Navigate to order detail page
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://89.116.187.72:3001/orders', {
          method: 'POST'
        });

        if (!response.ok) {
          console.log(response);
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('API Response:', data); // Log the response
        console.log('First Response:', data.orders[0]); // Log the response
        setOrders(data.orders || []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError(`Failed to fetch orders: ${err.message}`);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  console.log('Orders state:', orders);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="order-list">
      <h1>Orders</h1>
      {orders.length === 0 ? (
        <p>No orders found</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Order Date</th>
              <th>Status</th>
              <th>Items Count</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              const status = (order.closed_at != null || (order.metadata && order.metadata.OrderStatus && order.metadata.OrderStatus === 'Closed')) ? 'completed' : 'pending';
              return (
                <tr key={order.id} onClick={() => handleRowClick(order.id)}>
                  <td>{i++}</td>
                  <td>{new Date(order.created_at).toLocaleString()}</td>
                  <td className={status}>{status.charAt(0).toUpperCase() + status.slice(1)}</td>
                  <td>{order.line_items.filter((item) => (item.metadata?.type)).length}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};


export default OrderList;